import React from 'react';
import { Timeline } from 'antd';
import "./Content.css";
import { Link } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Footer from "./Footer";


function Timelines () {
    return (
        <div className="font-['Optimistic'] pt-[8vh] pb-[2vh] relative"
             wrap="wrap"
        > 
            <div className="flex items-center justify-center text-center pb-[5vh]">
                <div className="max-w-[70vw]">
                    <div className="text-[50px] font-bold m-[0] max-w-[80vw]">
                    <Link to="/"
                        className="
                            text-PuranaLite
                            italic
                            animate-titleF
                            opacity-0
                            select-none
                            transition-color
                            hover:text-Blood
                            no-underline
                            decoration-inherit
                    ">
                        Time
                    </Link>
                    <Link to="/"
                        className="
                            text-PuranaWhite
                            italic
                            opacity-0
                            animate-titleB
                            select-none
                            transition-color
                            hover:text-Blood
                            no-underline
                            decoration-inherit         
                    ">
                        Line
                    </Link>
                    </div>
                    <div className="opacity-0 animate-default mt-[5vh]">
                        <Timeline
                            pending="下一次...会是什么?"
                            className='font-bold text-PuranaWhite'
                            mode="left"
                            items={[
                                {
                                    label: '1973年3月3日',
                                    children: '《濒危野生动植物种国际贸易公约》颁布',
                                    color: "green",
                                },
                                {
                                    label: '1988年11月8日',
                                    children: '《中华人民共和国野生动物保护法》颁布',
                                    color: "green",
                                },
                                {
                                    label: '2004年8月28日',
                                    children: '《中华人民共和国野生动物保护法》第一次修正',
                                    color: "green",
                                },
                                {
                                    label: '2009年8月27日',
                                    children: '《中华人民共和国野生动物保护法》第二次修正',
                                    color: "green",
                                },
                                {
                                    label: '2014年4月',
                                    children: '台湾云豹灭绝',
                                    color: "red",
                                },
                                {
                                    label: '2016年7月2日',
                                    children: '《中华人民共和国野生动物保护法》第一次修订',
                                    color: "green",
                                },
                                
                                {
                                    label: '2017年',
                                    children: '白鱀豚被推定为功能性灭绝',
                                    color: "gray",
                                },
                                {
                                    label: '2018年10月26日',
                                    children: '《中华人民共和国野生动物保护法》第三次修正',
                                    color: "green",
                                },
                                {
                                    label: '2022年',
                                    children: '中国分布的白掌长臂猿、北白颊长臂猿符合野外灭绝的标准',
                                    color: "gray",
                                },
                                {
                                    label: '2022年7月21日',
                                    children: '长江白鲟野生种群灭绝',
                                    color: "red",
                                },
                                {
                                    label: '2022年12月30日',
                                    children: '《中华人民共和国野生动物保护法》第二次修订',
                                    color: "green",
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
            <div className="absolute bottom-[3vh]">
                <div className="font-semibold opacity-[0.85] select-none italic text-PuranaWhite">
                    人与自然的<span className="text-PuranaLite">和谐共生</span>
                    <br></br>
                    <br></br>
                </div>
                <div className="font-semibold opacity-[0.5] select-none">
                    <Footer />
                </div>
            </div>
            <SpeedInsights />
            <Analytics />
        </div>
    );
};

export default Timelines;