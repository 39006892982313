import "./Content.css";
import React, { useRef } from "react";
import { Button } from "antd";
import { Card } from "antd";
import { Flex } from "antd";
import { Link } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react";
import { useState, useEffect } from "react";
import { PictureFilled } from '@ant-design/icons';
import Footer from "./Footer";

function jump(prop){
    const cate = prop;
    var target = document.getElementById(cate);
    if (target) {
        target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

function Content(props){
    const CAMPUS = props.campus;
    const CATEGORIES = props.cates;
    const mediaElem = useRef(null);
    const [mediaState, updateState] = useState("");


    useEffect(() => {
      let bar = document.getElementById('prgbtn');
      let controlBtn = document.getElementById('control');
      if (mediaElem.current && mediaState) {
      const audio = mediaElem.current;
      audio.load(); // 加载新的音频资源
      const playAudio = async () => {
        try {
        await audio.play(); // 等待播放请求完成
        controlBtn.innerText = "暂停";
        // 使用时间戳创建一个唯一的动画名称
        const aniChanging = `widthChange-${Date.now()}`;
        bar.style.animation = `${aniChanging} ${audio.duration}s ease-in-out`;

        // 定义动画关键帧
        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`@keyframes ${aniChanging} {
            from { width: 0; }
            to { width: 100%; }
        }`, styleSheet.cssRules.length);
        } catch (err) {
        console.error("Audio playback failed:", err);
        }
      };
      playAudio();
      }
    }, [mediaState]); // 依赖mediaState变化来触发效果

    // 播放选定音频
    function playMediaOver(Url) {
        updateState(Url); // 更新状态触发useEffect中的逻辑
    }

    function shiftStatus (){
      let bar = document.getElementById('prgbtn');
      let controlBtn = document.getElementById('control');
      if (mediaElem.current && mediaState){
      if (mediaElem.current.paused){
          mediaElem.current.play();
          controlBtn.innerText = "暂停";
          bar.style.animationPlayState = 'running';
      } else {
          mediaElem.current.pause();
          controlBtn.innerText = "播放";
          bar.style.animationPlayState = 'paused';
      }
      }
    }

    function stop (){
        let controlBtn = document.getElementById('control');
        let bar = document.getElementById('prgbtn');
        updateState("");
        controlBtn.innerText = "未在播放";
        bar.style.width = 0;
    }

    function showImg (props){
      let imgBox = document.getElementById('imgBox');
      let img = document.getElementById('img');
      imgBox.style.display = "flex";
      img.src = props;
    }

    function closeImg (){
      let imgBox = document.getElementById('imgBox');
      imgBox.style.display = "none";
    }


  return( 
    <div
      className="font-['Optimistic'] py-[5vh]"
      wrap="wrap"
    >
      <div className="text-[50px] font-bold m-[0] max-w-[80vw]">
          <Link to="/"
                className="
                    text-PuranaLite
                    italic
                    animate-titleF
                    opacity-0
                    select-none
                    transition-color
                    hover:text-Blood
                    no-underline
                    decoration-inherit
          ">
              EndAnger
          </Link>
          <Link to="/"
                className="
                    text-[25px]
                    text-PuranaWhite
                    italic
                    opacity-0
                    animate-titleB
                    select-none
                    transition-color
                    ml-[5px]
                    hover:text-Blood
                    no-underline
                    decoration-inherit         
          ">
              {CAMPUS}
          </Link>
      </div>
      <div className="opacity-[0.85] pt-[2vh]">
        <Card
          className="w-fit my-[2vh] mr-[4vh] max-w-[60vw] relative shadow-inner transform-gpu bg-[#cccccc88] border-0"
        >
          {CATEGORIES.map((category) => (
            <Button
              className="
                      mx-[0.3vw]
                      my-[0.5vh]
                      font-bold
                      text-[15px]
                      w-fit
                      h-fit
                      font-['Optimistic']
                      shadow-md
                      bg-AronaH
                      hover:bg-PuranaLite
                      skew-x-[-10deg]"
              onClick={() => jump(category.title)}
              type="primary"
            >
              {'>>'} {category.titleDis}
            </Button>
          ))}
        </Card>
      </div>
      <Flex wrap="wrap" className="opacity-[0.85] py-[2vh] mb-[3vh]">
        {CATEGORIES.map((category) => (
          <Card
            className="w-fit my-[2vh] mr-[4vh] relative transform-gpu bg-[#cccccc88] border-0"
            hoverable={true}
            bordered={false}
            key={category.title}
            id={category.title}
            title={<div className="text-[20px] font-['Optimistic'] select-none text-PuranaWhite">{category.titleDis}</div>}
          >
            <Flex wrap="wrap">
              {category.buttons.map((element, index) => (
                <div className="shrink-0">
                  <Button
                    className="
                      rounded-r-none
                      mx-[0.3vw]
                      my-[0.5vh]
                      pr-[5px]
                      font-bold
                      text-[15px]
                      w-fit h-fit
                      bg-PuranaBlue
                      hover:bg-Arona
                      font-['Optimistic']
                      skew-x-[-10deg]"
                    onClick={() => {
                        playMediaOver("./audio/" + category.title + "/" + element.url + ".mp3")
                      }
                    }
                    type="primary"
                  >
                    {element.name}
                  </Button>
                  <Button
                    onClick={() => showImg("./pic/" + category.title + "/" + element.url + ".webp")}
                    className="
                      rounded-l-none
                      ml-[-0.5vw]
                      my-[0.5vh]
                      font-bold
                      pl-[3px]
                      text-[15px]
                      w-fit h-fit
                      bg-PuranaBlue
                      hover:bg-Arona
                      font-['Optimistic']
                      skew-x-[-10deg]"
                    type="primary"
                  >
                        <PictureFilled/>
                  </Button>
                </div>
              ))}
            </Flex>
          </Card>
        ))}
      </Flex>
      <div className="font-semibold opacity-[0.85] select-none italic text-PuranaWhite">
        人与自然的<span className="text-PuranaLite">和谐共生</span>
        <br></br>
        <br></br>
      </div>
      <div className="font-semibold opacity-[0.5] select-none">
          <Footer />
      </div>
      <SpeedInsights />
      <div className="
                  fixed
                  justify-center
                  items-center
                  opacity-[0]
                  bottom-[20px]
                  right-[20px]
                  text-right
                  animate-bar
      ">
        <div className="
                  m-[5px]
                  min-w-[100px]
                  max-w-[100px]
                  h-[10px]
                  rounded-[5px]
                  border-[2px]
                  border-PuranaBlue
                  border-solid
                  bg-[#cccccc]
                  skew-x-[-10deg]
                  transform-gpu"
        >
          <div className="
                    w-0
                    max-w-[100px]
                    h-[10px]
                    rounded-[3px]
                    ease-linear
                    bg-PuranaLite"
                id="prgbtn">
              <a className="text-transparent" id="playIndi">FINISHED</a>
          </div>
        </div>
        <div className="flex">
          <Button
            id = "stop"
            onClick={stop}
            type="primary"
            className="
                    m-[5px]
                    mb-0
                    font-bold
                    text-[15px]
                    w-fit h-fit
                    font-['Optimistic']
                    skew-x-[-10deg] transform-gpu
                    bg-PuranaLite
                    hover:bg-PuranaBlue
                    active:bg-PuranaBlue
          ">
              停止
          </Button>
          <Button
          id = "control"
          onClick={shiftStatus}
          type="primary"
          className="
                  m-[5px]
                  mb-0
                  font-bold
                  text-[15px]
                  w-fit h-fit
                  font-['Optimistic']
                  skew-x-[-10deg] transform-gpu
          ">
            未在播放
          </Button>
          <Button
            onClick={scrollToTop}
            type="primary"
            className="
                  m-[5px]
                  mb-0
                  font-bold
                  text-[15px]
                  w-fit h-fit
                  font-['Optimistic']
                  skew-x-[-10deg] transform-gpu
          ">
              回到顶部
          </Button>
        </div>
      </div>
      <audio ref={mediaElem} src={mediaState} onEnded={() => stop()}/>
      <div
        id="imgBox"
        onClick={() => closeImg()}
        className="
          fixed
          hidden
          justify-center
          items-center
          bg-[#00000088]
          inset-0
          w-[100%]"
        >
          <div className="w-[50%]">
            <img
              id="img"
              className="
                w-[100%]
                text-[#ffffff77]
                text-[60px] rounded-[5px]"
              src=""
              alt="No Image"
            />
          </div>
      </div>
      <Analytics />
    </div>
  );
}

export default Content;
