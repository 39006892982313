export const categories = [
  {
    "title": "artiodactyla",
    "titleDis": "偶蹄目",
    "buttons": [
      {
        "name": "野牦牛",
        "url": "bosMutus"
      },
      {
        "name": "贡山羚牛",
        "url": "budorcasTaxicolor"
      },
      {
        "name": "野骆驼",
        "url": "camelusFerus"
      },
      {
        "name": "马鹿",
        "url": "cervusCanadensis"
      },
      {
        "name": "水鹿",
        "url": "cervusEquinus"
      },
      {
        "name": "梅花鹿",
        "url": "cervusNippon"
      },
      {
        "name": "麋鹿",
        "url": "elaphurusDavidianus"
      },
      {
        "name": "獐",
        "url": "hydropotesInermis"
      },
      {
        "name": "黑麂",
        "url": "muntiacusCrinifrons"
      },
      {
        "name": "藏羚",
        "url": "pantholopsHodgsonii"
      },
      {
        "name": "白唇鹿",
        "url": "przewalskiumAlbirostris"
      },
      {
        "name": "高鼻羚羊",
        "url": "saigaTatarica"
      }
    ]
  },
  {
    "title": "carnivora",
    "titleDis": "食肉目",
    "buttons": [
      {
        "name": "大熊猫",
        "url": "ailuropodaMelanoleuca"
      },
      {
        "name": "小熊猫",
        "url": "ailurusFulgens"
      },
      {
        "name": "熊狸",
        "url": "arctictisBinturong"
      },
      {
        "name": "亚洲胡狼",
        "url": "canisAureus"
      },
      {
        "name": "狼",
        "url": "canisLupus"
      },
      {
        "name": "豺",
        "url": "cuonAlpinus"
      },
      {
        "name": "荒漠猫",
        "url": "felisBieti"
      },
      {
        "name": "丛林猫",
        "url": "felisChaus"
      },
      {
        "name": "貂熊",
        "url": "guloGulo"
      },
      {
        "name": "水獭",
        "url": "lutraLutra"
      },
      {
        "name": "紫貂",
        "url": "martesZibellina"
      },
      {
        "name": "云豹",
        "url": "neofelisNebulosa"
      },
      {
        "name": "兔狲",
        "url": "otocolobusManul"
      },
      {
        "name": "豹",
        "url": "pantheraPardus"
      },
      {
        "name": "虎",
        "url": "pantheraTigris"
      },
      {
        "name": "雪豹",
        "url": "pantheraUncia"
      },
      {
        "name": "椰子猫",
        "url": "paradoxurusHermaphroditus"
      },
      {
        "name": "金猫",
        "url": "pardofelisTemminckii"
      },
      {
        "name": "西太平洋斑海豹",
        "url": "phocaLargha"
      },
      {
        "name": "棕熊",
        "url": "ursusArctos"
      },
      {
        "name": "黑熊",
        "url": "ursusThibetanus"
      },
      {
        "name": "大灵猫",
        "url": "viverraZibetha"
      },
      {
        "name": "沙狐",
        "url": "vulpesCorsac"
      },
      {
        "name": "藏狐",
        "url": "vulpesFerrilata"
      }
    ]
  },
  {
    "title": "cetacea",
    "titleDis": "鲸目",
    "buttons": [
      {
        "name": "小须鲸",
        "url": "balaenopteraAcutorostrata"
      },
      {
        "name": "蓝鲸",
        "url": "balaenopteraMusculus"
      },
      {
        "name": "长须鲸",
        "url": "balaenopteraPhysalus"
      },
      {
        "name": "灰鲸",
        "url": "eschrichtiusRobustus"
      },
      {
        "name": "北太平洋露脊鲸",
        "url": "eubalaenaJaponica"
      },
      {
        "name": "抹香鲸",
        "url": "physeterMacrocephalus"
      },
      {
        "name": "中华白海豚",
        "url": "sousaChinensis"
      }
    ]
  },
  {
    "title": "primates",
    "titleDis": "灵长目",
    "buttons": [
      {
        "name": "西白眉长臂猿",
        "url": "hoolockHoolock"
      },
      {
        "name": "东白眉长臂猿",
        "url": "hoolockLeuconedys"
      },
      {
        "name": "白掌长臂猿",
        "url": "hylobatesLar"
      },
      {
        "name": "短尾猴",
        "url": "macacaArctoides"
      },
      {
        "name": "猕猴",
        "url": "macacaMulatta"
      },
      {
        "name": "藏酋猴",
        "url": "macacaThibetana"
      },
      {
        "name": "西黑冠长臂猿",
        "url": "nomascusConcolor"
      },
      {
        "name": "海南长臂猿",
        "url": "nomascusHainanus"
      },
      {
        "name": "北白颊长臂猿",
        "url": "nomascusLeucogenys"
      },
      {
        "name": "滇金丝猴",
        "url": "rhinopithecusBieti"
      },
      {
        "name": "黑叶猴",
        "url": "trachypithecusFrancoisi"
      }
    ]
  },
  {
    "title": "perissodactyla",
    "titleDis": "奇蹄目",
    "buttons": [
      {
        "name": "普氏野马",
        "url": "equusFerus"
      },
      {
        "name": "蒙古野驴",
        "url": "equusHemionus"
      },
      {
        "name": "藏野驴",
        "url": "equusKiang"
      }
    ]
  },
  {
    "title": "proboscidea",
    "titleDis": "长鼻目",
    "buttons": [
      {
        "name": "亚洲象",
        "url": "elephasMaximus"
      }
    ]
  },
  {
    "title": "rodentia",
    "titleDis": "啮齿目",
    "buttons": [
      {
        "name": "河狸",
        "url": "castorFiber"
      }
    ]
  },
];
  