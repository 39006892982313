import "./Content.css";
import React from "react";

function Footer() {
    return(
        <div>
            EndAnger 2024
            <br></br>
            Made by RakuAki
            <br></br>
            Powered by React & Tailwind CSS & Ant Design
        </div>
    );
}

export default Footer;