import "./App.css";
import React from "react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Default from './components/Default';
import Content from './components/Content';
import Timelines from "./components/Timelines";
import { categories } from "./categories/categories";


function App(){
  return( 
    <div
      className="bg-gradient-to-br from-[#517e4e] to-[#4d618c] h-full min-h-screen px-[3vw] font-['Optimistic']"
      wrap="wrap"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Default />} />
          <Route path="/audios" element={<Content campus="Media" cates={categories}/>} />
          <Route path="/timelines" element={<Timelines/>} />
        </Routes>
      </BrowserRouter>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
