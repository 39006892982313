import "./Content.css";
import React from "react";
import { Link } from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Switcher from "./Switcher";
import Footer from "./Footer";

function Default(){
  return( 
    <div
      className="font-['Optimistic'] h-full flex flex-col justify-center"
      wrap="wrap"
    > 
      <div className="flex items-center justify-center pt-[28vh] pb-[35vh]">
        <div className="max-w-[70vw]">
          <h1 className="text-[60px] font-bold m-[0] flex">
            <Link to="/"
                  className="
                      text-PuranaLite
                      italic
                      animate-titleF
                      opacity-0
                      select-none
                      transition-color
                      hover:text-Blood
                      no-underline
                      decoration-inherit
            ">
                En
            </Link>
            <Link to="/"
                  className="
                      text-PuranaWhite
                      italic
                      opacity-0
                      animate-titleB
                      select-none
                      transition-color
                      hover:text-Blood
                      no-underline
                      decoration-inherit         
            ">
                dAnger
            </Link>
          </h1>
          <div className="opacity-0 animate-default mt-[3vh]">
            <a id="text" className="italic text-[30px] animate-colorCycle opacity-[0.85] font-bold">
              您希望从何处开始...?
            </a>
            <Switcher/>
          </div>
        </div>
      </div>
      <div className="absolute bottom-[3vh]">
        <div className="font-semibold opacity-[0.85] select-none italic text-PuranaWhite">
          人与自然的<span className="text-PuranaLite">和谐共生</span>
          <br></br>
          <br></br>
        </div>
        <div className="font-semibold opacity-[0.5] select-none">
          <Footer />
        </div>
      </div>
      <SpeedInsights />
      <Analytics />
    </div>
  );
}

export default Default;
